import { motion } from "framer-motion";
import styled from "styled-components";

import { Section } from "@/styled";

export const HomeWrapper = styled.main<{
  gap?: string;
}>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: ${prop => prop.gap || "0px"};

  * {
    margin: 0;
  }
`;

export const TopSection = styled(Section)`
  h1 {
    font-family: Alliance-Bold;
    font-weight: 700;
    font-size: 50px;
    line-height: 1.2;
  }
`;

export const CaseSection = styled(Section)`
  background: #16171a;
  color: #fff;
  * {
    color: inherit;
  }

  h1 {
    font-family: Alliance-SemiBold;
    font-weight: 600;
    font-size: 28px;
    line-height: 1.2;
  }

  .count-item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    .count-desc {
      font-family: Alliance;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
    }
    .count-num {
      font-family: Alliance-Bold;
      font-weight: 700;
      font-size: 70px;
      line-height: 1.2;
    }
  }

  .case-desc {
    flex: 0 1 50%;
    gap: 20px;
    p {
      font-size: 18px;
      line-height: 1.5;
    }
    p::before {
      content: "• ";
    }
  }
`;

export const PartnerSection = styled(Section)`
  background: #f2f3f6;

  h1 {
    font-family: Alliance-SemiBold;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.2;
  }

  .partner-card {
    width: 350px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: #000;
    transition: all 0.3s;
    /* font */
    font-family: Alliance-Medium;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.2;
    img {
      width: 270px;
      height: 100%;
      object-fit: contain;
    }
    &:hover:not([data-disabled]) {
      transform: scale(1.1);
    }
    &[data-disabled="true"] {
      background: unset !important;
      color: unset !important;
    }
  }
`;

export const FAQSection = styled(Section)`
  h1 {
    font-family: Alliance-SemiBold;
    font-weight: 600;
    font-size: 32px;
    line-height: 1.2;
  }

  .faq-item {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-top: 1px gray solid;
    padding: 12px 0;
    .faq-title {
      font-family: Alliance;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.2;
    }
    .faq-desc {
      font-family: Alliance;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
    }
  }
`;

export const FooterSection = styled(Section)`
  background: #000;
  color: #fff;
  * {
    color: inherit;
  }

  h1 {
    width: 100%;
    white-space: nowrap;
    text-align: center;
    font-family: Alliance-SemiBold;
    font-weight: 600;
    font-size: calc(15vw - 7px);
    line-height: 1.2;
  }

  .footer-bottom {
    width: 100%;
    flex: 1 1 auto;
    padding: 20px 0;
    border-top: 1px solid rgb(66, 67, 70);
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* font */
    font-family: Alliance;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    .footer-link {
      opacity: 0.6;
      &:hover {
        opacity: 0.8;
      }
    }
    .footer-button {
      img {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
  }
`;

export const Title = styled(motion.h1)<{
  fontSize?: string;
  lineHeight?: string;
  textAlign?: string;
}>`
  font-family: Alliance-SemiBold;
  font-size: ${prop => prop.fontSize || "50px"};
  font-weight: 600;
  line-height: ${prop => prop.lineHeight || 1.2};
  letter-spacing: -0.015em;
  text-align: ${prop => prop.textAlign || "left"};
  /* color: #232325; */
`;

export const Description = styled(motion.p)<{
  preLine?: boolean;
  maxWidth?: string;
}>`
  font-family: Alliance;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.015em;
  text-align: left;
  ${prop => prop.preLine && "white-space: pre-line;"}
  max-width: ${prop => prop.maxWidth || "100%"};

  .bold {
    font-family: Alliance-Bold;
    font-weight: 700;
  }
`;
