import styled from "styled-components";

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
`;

export const BodyWrapper = styled.div`
  min-height: 100vh;
  /* padding: 0 30px; */
`;
