import React from "react";

import { motion } from "framer-motion";

import {
  Title,
  HomeWrapper,
  TopSection,
  CaseSection,
  Description,
  PartnerSection,
  FAQSection,
  FooterSection,
} from "./styled";

import MailIcon from "@/assets/icons/mail.svg";
import CamlLogo from "@/assets/logo/caml.png";
import DBSLogo from "@/assets/logo/dbs.svg";
import FordLogo from "@/assets/logo/ford.svg";
import { FlexRow, RoundCard, Section } from "@/styled";
import { fadeIn, textVariant } from "@/util/motion";

const Home: React.FC = () => {
  return (
    <HomeWrapper>
      <TopSection padding='60px 90px'>
        <Title
          textAlign='center'
          initial='hidden'
          whileInView='show'
          viewport={{ once: true, amount: 0.25 }}
          variants={textVariant(0.5)}
        >
          Modular On-Chain Finance for RWA (Real-World Asset) Building Trustless
          Finance
        </Title>
      </TopSection>
      <CaseSection padding='60px 90px' gap='60px'>
        <FlexRow width='100%' justifyContent='center'>
          <Title
            textAlign='center'
            initial='hidden'
            whileInView='show'
            viewport={{ once: true, amount: 0.25 }}
            variants={textVariant(0.75)}
          >
            An industry-first blockchain solution for supply chain financing
            since 2019
          </Title>
        </FlexRow>
        <FlexRow width='100%' gap='40px' justifyContent='space-between'>
          <FlexRow
            gap='40px'
            initial='hidden'
            whileInView='show'
            viewport={{ once: true, amount: 0.25 }}
            variants={fadeIn("right", "tween", 1, 1)}
          >
            <div className='count-item'>
              <p className='count-desc'>Financial Institutions</p>
              <p className='count-num'>4</p>
            </div>
            <div className='count-item'>
              <p className='count-desc'>Business User Nodes</p>
              <p className='count-num'>60+</p>
            </div>
            <div className='count-item'>
              <p className='count-desc'>Business Loans</p>
              <p className='count-num'>€13M</p>
            </div>
          </FlexRow>
          <Section
            className='case-desc'
            initial='hidden'
            whileInView='show'
            viewport={{ once: true, amount: 0.25 }}
            variants={fadeIn("left", "tween", 1, 1)}
          >
            <Description>
              Our product supported SMEs on their business loans based on
              business contracts and commercial payments.
            </Description>
            <Description>
              Our product helped financial institutions reduce risk and fraud by
              providing verifiable data of business flow.
            </Description>
            <Description>
              Through tokenization, SMEs as well as their suppliers in all tiers
              also get better liquidity by leveraging the credit of the anchor
              buyer company.
            </Description>
          </Section>
        </FlexRow>
      </CaseSection>
      <PartnerSection padding='60px 90px' gap='20px'>
        <Title
          initial='hidden'
          whileInView='show'
          viewport={{ once: true, amount: 0.25 }}
          variants={textVariant(0.5)}
        >
          Partners
        </Title>
        <FlexRow
          width='100%'
          gap='36px'
          initial='hidden'
          whileInView='show'
          viewport={{ once: true, amount: 0.25 }}
          variants={fadeIn("up", "tween", 0.5, 1)}
        >
          <RoundCard className='partner-card'>
            <img src={DBSLogo} />
          </RoundCard>
          <RoundCard className='partner-card'>
            <img src={FordLogo} />
          </RoundCard>
          <RoundCard className='partner-card'>
            <img src={CamlLogo} />
          </RoundCard>
          <RoundCard className='partner-card' data-disabled>
            and more...
          </RoundCard>
        </FlexRow>
      </PartnerSection>
      <FAQSection padding='60px 90px' gap='40px'>
        <Title
          initial='hidden'
          whileInView='show'
          viewport={{ once: true, amount: 0.25 }}
          variants={textVariant(0.5)}
        >
          FAQ
        </Title>
        <Section gap='30px'>
          <motion.div
            className='faq-item'
            initial='hidden'
            whileInView='show'
            viewport={{ once: true, amount: 0.25 }}
            variants={textVariant(0.5)}
          >
            <p className='faq-title'>What is trustless finance?</p>
            <p className='faq-desc'>
              In the blockchain, we don’t need to know each other to give
              personal/professional trust to deal with our assets properly.
              Instead, the function is built based on data and truth that will
              operate unstained and autonomously.
            </p>
          </motion.div>
          <motion.div
            className='faq-item'
            initial='hidden'
            whileInView='show'
            viewport={{ once: true, amount: 0.25 }}
            variants={textVariant(0.5)}
          >
            <p className='faq-title'>Who are we?</p>
            <p className='faq-desc'>
              We are a team of passionate builders consisting of veteran
              blockchain developers, disruptive innovation product managers, and
              experienced finance experts. The core members are from top-tier
              companies like Opera, Alibaba, Hashkey, Huobi Exchange, and
              co-founders from leading Web3 projects.
            </p>
          </motion.div>
        </Section>
      </FAQSection>
      <FooterSection
        padding='80px 40px 0'
        gap='20px'
        initial='hidden'
        whileInView='show'
        viewport={{ once: true, amount: 0.25 }}
        variants={fadeIn("up", "tween", 0, 2)}
      >
        <Title>Citta Network</Title>
        <div className='footer-bottom'>
          <FlexRow gap='30px'>
            <p>Citta Network © 2024</p>
            <a className='footer-link'>Team</a>
            <a className='footer-link' href='mailto:lshen01@hotmail.com'>
              Contact
            </a>
          </FlexRow>
          <FlexRow className='footer-button' gap='20px' flex='0 0 auto'>
            <FlexRow padding='10px'>
              <a href='mailto:lshen01@hotmail.com'>
                <img src={MailIcon} />
              </a>
            </FlexRow>
          </FlexRow>
        </div>
      </FooterSection>
    </HomeWrapper>
  );
};

export default Home;
