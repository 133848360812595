import React from "react";

import { useNavigate } from "react-router-dom";

import { Brand, HeaderWrapper, Navigation } from "./styled";

const Header = (): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <HeaderWrapper>
      <Brand
        onClick={() => {
          navigate("/");
        }}
      >
        <span>
          Citta
          <br />
          Network
        </span>
      </Brand>
      <Navigation width='100%' flex='1 1 auto' gap='8px'>
        <a>About</a>
        <a>Use Case</a>
        {/* <a>Roadmap</a> */}
        <a>Team</a>
        <a href='mailto:lshen01@hotmail.com'>Contact</a>
      </Navigation>
    </HeaderWrapper>
  );
};

export default Header;
