import styled from "styled-components";

import { FlexRow } from "@/styled";

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 46px;
  padding: 12px 40px;

  /* position: sticky; */
  top: 0;
  background-color: #ffffff;
`;

export const Brand = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;

  span {
    font-family: Alliance-Bold;
    font-size: 26px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #232325;
  }
`;

export const Navigation = styled(FlexRow)`
  a {
    padding: 6px 14px;
    font-family: Alliance;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
      background-color: rgba(6, 7, 10, 0.06);
    }
  }
`;
